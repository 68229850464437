@import "variables";
@import "framework/framework";
@import '../../node_modules/magnific-popup/dist/magnific-popup';
@import '../../node_modules/owl.carousel/src/scss/owl.carousel';
@import '../../node_modules/aos/src/sass/aos.scss';

html {
	color: $color-white;
	font-family: "titling-gothic-fb-extended";
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	position: relative;
	text-transform: uppercase;
}

body {
	background: $color-black;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

.btn {
	background: $color-black;
	border: 2px solid $color-white;
	border-radius: 0;
	color: $color-white;
	display: inline-block;
	font-weight: 700;
	letter-spacing: letter-spacing(15);
	opacity: 1;
	padding: rem(7) rem(15);
	text-align: center;
	text-indent: text-indent(15);
	text-transform: uppercase;
	vertical-align: middle;

	@include hover-focus {
		opacity: 0.8;
	}

	&.btn-sm {
		font-family: "titling-gothic-fb-narrow";
		font-size: rem(15);
		font-weight: 400;
	}

	&.btn-md {
		font-weight: 400;
	}
}

.container {
	max-width: 980px;
}

header {
	left: 0;
	padding: rem(50) 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	z-index: 1030;

	.container {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		max-width: 1230px;

		nav {
			flex: 1 1 40%;
			max-width: 40%;

			@include tablet-down {
				display: none;
			}

			> ul {
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(14);
					vertical-align: middle;

					@include desktop-sm-down {
						font-size: rem(12);
					}

					+ li {
						margin-left: rem(25);

						@include desktop-sm-down {
							margin-left: rem(20);
						}
					}

					> a {
						color: $color-white;
						opacity: 1;

						@include hover-focus {
							opacity: 0.8;
						}
					}
				}

			}

			&:first-child {
				text-align: center;
			}

			&:last-child {
				text-align: center;
			}
		}

		h1 {
			flex: 0 0 auto;
			font-size: rem(54);
			font-weight: 500;
			margin: 0;
			position: relative;

			@include desktop-sm-down {
				font-size: rem(45);
			}

			@include tablet-down {
				font-size: rem(40);
			}

			&:before, &:after {
				background: #4aa494;
				content: '';
				display: inline-block;
				height: 2px;
				vertical-align: middle;
				width: rem(65);

				@include desktop-sm-down {
					width: rem(45);
				}

				@include tablet-down {
					display: none;
				}
			}

			&:after {
				margin-left: rem(15);
			}

			&:before {
				margin-right: rem(15);
			}
		}
	}
}

main {
	section {
		margin-bottom: rem(80);

		.section-title {
			font-size: rem(25);
			font-weight: 500;
			margin: 0 0 rem(30) 0;

			@include tablet-down {
				text-align: center;
			}
		}

		.section-content {
			.more {
				text-align: center;
			}
		}

		&#cta {
			/*height: 100vh;*/
			padding-top: 200px;
			position: relative;
			text-align: center;

			@include tablet-down {
				padding-top: 150px;
			}

			.container {
				max-width: 501px;

				.section-content {
					bottom: 0;


					> img {
						margin: 0 auto;
					}

					> .overlay {
						bottom: 10px;
						left: 0;
						/*position: absolute;*/
						margin-top: rem(25);
						right: 0;

						text-align: center;

						> .btns {
							margin-bottom: rem(25);

							@include phone-sm-down {
								margin-bottom: rem(35);
							}

							> .btn {
								min-width: rem(200);

								@include phone-sm-down {
									display: block;
									margin: 0 auto;
									max-width: rem(220);
								}

								+ .btn {
									margin-left: rem(30);

									@include phone-sm-down {
										margin-left: auto;
										margin-top: rem(15);
									}
								}
							}
						}

						> img {
							margin: 0 auto;
						}
					}
				}
			}
		}

		&#music {
			text-align: center;

			.container {
				@include phone-down {
					padding: 0;
				}
			}

			.btn {
				margin-top: rem(25);
			}
		}

		&#videos {
			.section-title {
				display: none;

				@include tablet-down {
					display: block;
				}
			}

			.section-content {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				@include tablet-down {
					display: block;
				}

				> div {
					flex: 1 1 auto;

					&:last-child {
						max-width: rem(250);
						padding-left: rem(30);
						text-align: center;

						@include tablet-down {
							max-width: 100%;
							padding-left: 0;
						}

						.section-title {
							display: block;
							@include tablet-down {
								display: none;
							}
						}

						.btn {
							margin-top: rem(20);

							@include tablet-down {
								margin-top: rem(30);
							}
						}

						.videos {
							div {
								+ div {
									margin-top: rem(20);

									@include tablet-down {
										margin-top: 0;
									}
								}
							}

							@include tablet-down {
								display: flex;
								flex-direction: row;
								flex-wrap: nowrap;
								margin: 30px -15px 0 -15px;

								> div {
									flex: 0 0 50%;
									padding: 0 15px;
								}
							}
						}
					}
				}
			}
		}

		&#tour {
			.section-content {
				#events {
					.no-events {
						padding: 0 15px;
					}

					> div {
						align-items: center;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						letter-spacing: letter-spacing(15);
						margin: 0 -15px;
						text-indent: text-indent(15);

						@include tablet-down {
							display: block;
							text-align: center;
						}

						> div {
							padding: 0 15px;

							&.event-date {
								flex: 0 0 17%;
							}

							&.event-location {
								flex: 0 0 30%;
								font-weight: 700;

							}

							&.event-venue {
								flex: 0 0 30%;

							}

							&.event-links {
								flex: 0 0 22%;
								text-align: right;

								@include tablet-down {
									text-align: center;
								}
							}

							+ div {
								margin-top: rem(15);
							}
						}

						+ div {
							margin-top: rem(10);

							@include tablet-down {
								margin-top: rem(30);
							}
						}
					}
				}
			}
		}

		&#follow {
			.section-content {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				@include tablet-down {
					align-items: center;
					flex-direction: column-reverse;
					flex-wrap: wrap;
				}

				> div {
					&:first-child {
						flex: 0 0 rem(220);
						max-width: rem(220);
						padding-right: rem(20);

						@include tablet-down {
							flex: 1 0 auto;
							max-width: 100%;
							padding-right: 0;
							width: 100%;

							.section-title {
								display: none;
							}
						}

						> .follow-btns {
							list-style: none;
							margin: 0;
							padding: 0;

							@include tablet-down {
								align-items: center;
								display: flex;
								flex-direction: row;
								flex-wrap: wrap;
								justify-content: center;
								margin: 15px -9px 0 -9px;
							}

							> li {
								@include tablet-down {
									flex: 0 0 50%;
									padding: 9px;

									&:first-child {
										order: 10;
									}
								}

								> a {
									display: block;
								}

								+ li {
									margin-top: rem(7);

									@include tablet-down {
										margin-top: 0;
									}
								}
							}
						}

						> .socials {
							list-style: none;
							margin: 15px -15px;
							padding: 0;
							text-align: center;

							@include tablet-down {
								margin: 30px -15px;
							}

							> li {
								display: inline-block;
								font-size: rem(24);
								padding: 15px;
								vertical-align: middle;

								@include tablet-down {
									padding: 0;
									+ li {
										margin-left: rem(10);
									}
								}

								> a {
									display: inline-block;
									opacity: 1;
									vertical-align: middle;

									@include hover-focus {
										opacity: 0.8;
									}

									> svg {
										height: rem(24);
										width: auto;

										path {
											fill: $color-white;
										}
									}
								}
							}

						}

						> form {
							min-height: rem(75);
							text-align: center;

							@include tablet-down {
								align-items: flex-end;
								display: flex;
								flex-direction: row;
								flex-wrap: nowrap;
								margin: 0 auto;
								max-width: rem(275);
								min-height: 0;
							}

							> input {
								background: $color-black;
								border: 0;
								border-bottom: 2px solid $color-white;
								color: $color-white;
								display: block;
								font-family: "titling-gothic-fb-narrow";
								font-size: rem(15);
								font-weight: 500;
								letter-spacing: letter-spacing(15);
								line-height: 1;
								margin-bottom: rem(10);
								padding: 0 rem(5) rem(5) rem(5);
								text-align: center;
								text-indent: text-indent(15);
								text-transform: uppercase;
								width: 100%;

								@include tablet-down {
									flex: 1 1 auto;
									margin-bottom: 0;
									margin-right: rem(10);
								}

								@include placeholder {
									color: $color-white;
								}
							}

							> .btn {
								@include tablet-down {
									flex: 0 0 auto;
									padding: rem(5);
								}
							}
						}
					}

					&:last-child {
						flex: 1 0 auto;

						@include tablet-down {
							width: 100%;
						}

						.section-title {
							display: none;

							@include tablet-down {
								display: block;
								text-align: center;
							}
						}

						> #instagram {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							margin-top: -9px;

							@include tablet-down {
								margin: 0 -9px;
							}

							> div {
								flex: 0 0 33.333333%;
								padding: 9px;

								@include tablet-down {
									flex: 0 0 50%;

									&:nth-child(n+5) {
										display: none;
									}
								}

								> a {
									display: block;
									height: 0;
									opacity: 1;
									padding-bottom: 100%;
									position: relative;

									> img {
										bottom: 0;
										height: 100%;
										left: 0;
										object-fit: cover;
										object-position: center top;
										position: absolute;
										right: 0;
										top: 0;
										width: 100%;
									}

									@include hover-focus {
										opacity: 0.8;
									}
								}
							}
						}
					}
				}
			}
		}

		&#store {
			.section-title {
				text-align: center;
			}

			.section-content {
				.merch {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					margin: 0 -15px;

					@include phone-down {
						display: block;
					}

					> div {
						flex: 0 0 33.333333%;
						margin-bottom: rem(40);
						padding: 0 15px;
						text-align: center;

						> img {
							margin: 0 auto;

							@include phone-down {
								max-width: rem(240);
							}
						}

						> span {
							display: block;
							font-weight: 700;
							margin: rem(15) 0;
						}

						> .btn-sm {
							min-width: rem(170);
						}
					}
				}
			}
		}
	}
}

footer {
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: rem(10);
	letter-spacing: letter-spacing(200);
	line-height: line-height(14, 10);
	padding: 0 0 rem(8) 0;
	text-align: center;
	text-indent: text-indent(200);

	.logos {
		margin-bottom: rem(15);

		a {
			display: inline-block;
			opacity: 1;
			vertical-align: middle;
			width: rem(55);

			svg {
				height: auto;
				width: 100%;

				path {
					fill: $color-white;
				}
			}

			@include hover-focus {
				opacity: 0.8;
			}
		}
	}

	p {
		margin: 0;

		a {
			@include hover-focus {
				text-decoration: underline;
			}
		}
	}
}